<template>
  <div>
    <v-container class="pb-0">
      <h1 class="my-8">{{ $t('heading.accountPage.title') }}</h1>

      <v-tabs
        prev-icon="$chevronleft"
        next-icon="$chevronright"
        center-active
        v-model="activeTab"
      >
        <v-tab v-ripple="false" class="p-1 font-weight-600 top-bar__item">
          {{ $t('heading.accountPage.tab.user.title') }}
        </v-tab>
        <v-tab v-ripple="false" class="p-1 font-weight-600 top-bar__item">
          {{ $t('heading.accountPage.tab.device.title') }}
        </v-tab>
      </v-tabs>
    </v-container>

    <v-divider class="mb-12"/>

    <v-container class="pt-0">
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <div
            style="height: 48px"
            class="d-flex justify-space-between align-center mb-8"
          >
            <h4 class="font-weight-light">{{ $t('heading.accountPage.tab.user.title') }}</h4>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div
            class="d-flex justify-space-between align-center mb-8"
            style="height: 48px"
          >
            <h4 class="font-weight-light">{{ $t('heading.accountPage.tab.device.title') }}</h4>
            <data-iterator-search :searchTermData.sync="searchTerm"/>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <v-card elevation="4">
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <v-form ref="form">
              <v-card-text class="pa-6 pb-0">
                <v-row>
                  <v-col cols="12" md="3" class="pa-4">
                    <p
                      class="
                        p-1
                        mb-0
                        font-weight-600
                        black--text
                        text--darken-2
                      "
                    >
                      {{ $t('heading.accountPage.tab.user.userSection.title') }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                    class="pb-0"
                    :class="$vuetify.breakpoint.mdAndDown ? 'pt-0' : 'pt-6'"
                  >
                    <v-row class="my-0">
                      <v-col
                        cols="12"
                        sm="6"
                        class="py-0"
                        v-for="field in personalInformation"
                        :key="field.name"
                      >
                        <label
                          v-if="field.title"
                          class="base--text p-3"
                          v-show="!field.hidden"
                        >{{ field.title }}
                        </label>
                        <v-text-field
                          v-if="field.type === 'text'"
                          outlined
                          dense
                          persistent-hint
                          single-line
                          :rules="field.rules"
                          v-model="formValue[field.name]"
                          class="v-input--md"
                        ></v-text-field>
                        <select-dropdown
                          v-if="field.type === 'select'"
                          outlined
                          dense
                          persistent-hint
                          single-line
                          :rules="field.rules"
                          :bold="false"
                          block
                          :menu-props="{
                            offsetY: true,
                            nudgeBottom: '8px',
                            closeOnContentClick: false,
                            offsetOverflow: true,
                            contentClass:
                              'custom-dropdown-select custom-dropdown-select--onboarding ',
                          }"
                          :items="field.options"
                          :value="formValue[field.name]"
                          @update:value="handleLanguage"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="my-0">
                      <v-col cols="12" sm="6" class="py-0">
                        <label class="base--text">{{
                            $t('form.label.emailAddress')
                          }}</label>
                        <v-text-field
                          outlined
                          dense
                          single-line
                          readonly
                          class="v-input--md"
                          persistent-hint
                          v-model="emailAddress"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <label class="base--text"></label>
                        <v-btn
                          btn
                          color="primary"
                          block
                          large
                          elevation="0"
                          outlined
                          @click="showChangeAddressEmailModal"
                          :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : ''"
                        >
                          {{ $t('button.emailAddress.change') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row style="margin: 0 -35px">
                  <v-col
                    class="pb-0"
                    :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
                  >
                    <v-divider/>
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col cols="12" sm="3">
                    <p
                      class="
                        p-1
                        mb-0
                        font-weight-600
                        black--text
                        text--darken-2
                      "
                    >{{ $t('heading.accountPage.tab.user.passwordSection.title') }}</p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="9"
                    class=""
                    :class="$vuetify.breakpoint.smAndDown ? 'pt-0' : 'py-6'"
                  >
                    <v-row class="my-0">
                      <v-col cols="12" sm="9" md="6" class="py-0">
                        <v-btn
                          btn
                          color="primary"
                          outlined
                          large
                          class="v-input--lg"
                          block
                          elevation="0"
                          @click="showChangePasswordModal"
                        >{{ $t('button.password.change') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row style="margin: 0 -35px">
                  <v-col class="py-0">
                    <v-divider/>
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col cols="12" md="3">
                    <p
                      class="
                        p-1
                        mb-0
                        font-weight-600
                        black--text
                        text--darken-2
                      "
                    >{{ $t('heading.accountPage.tab.user.securitySection.title') }}</p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                    class="pb-4"
                    :class="$vuetify.breakpoint.mdAndDown ? 'pt-0' : 'pt-6'"
                  >
                    <v-row class="py-0">
                      <v-col cols="12" sm="6" class="my-0 pb-0">
                        <label class="text-base"
                        >{{ $t('form.label.twoFactorAuth') }}</label>
                        <button-switch
                          :value="twoFactorAuthEnabled"
                          @click.native="toggleTwoFactorAuth"
                          :loading="loading"
                          start
                          :disabled="loading"
                          style="
                            display: block !important;
                            max-width: fit-content;
                          "
                          class="pa-0 flex-start ml-0 mt-2"
                        />
                        <!-- <v-switch
                          inset
                          dense
                          elevation="0"
                          class="pa-0"
                          :disabled="loading"
                          v-model="twoFactorAuthEnabled"
                          @change="toggleTwoFactorAuth"
                          hide-details
                          :loading="loading"
                        >
                        </v-switch> -->
                      </v-col>
                      <v-col cols="12" sm="6" class="my-0 pb-0">
                        <label class="base--text"
                        >{{ $t('form.label.deviceDetection') }}</label>
                        <select-dropdown
                          outlined
                          dense
                          persistent-hint
                          single-line
                          :bold="false"
                          block
                          :menu-props="{
                            offsetY: true,
                            nudgeBottom: '8px',
                            closeOnContentClick: false,
                            offsetOverflow: true,
                            contentClass:
                              'custom-dropdown-select custom-dropdown-select--onboarding ',
                          }"
                          :items="newDeviceDetectionOptions"
                          :itemText="'text'"
                          :itemValue="'value'"
                          :value="formValue['new_device_detection']"
                          @change="changeDeviceDetection"
                        />

                        <!-- <v-select>
                          <template v-slot:item="{ item, on, attrs }">
                            <div
                              class="
                                input-dropdown-item
                                w-100
                                d-flex
                                justify-space-between
                              "
                              v-on="on"
                              v-bind="attrs"
                            >
                              <span>{{ item.text ? item.text : item }}</span>
                              <v-icon class="ml-2 input-dropdown-item__icon"
                                >$check</v-icon
                              >
                            </div>
                          </template>
                        </v-select> -->
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="d-flex justify-end">
                <v-btn
                  btn
                  color="primary"
                  elevation="0"
                  @click="saveChanges"
                  large
                >
                  {{ $t('button.saveChanges') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-tab-item>

          <v-tab-item>
            <v-card-text class="pa-0">
              <v-row>
                <v-col>
                  <devices-table
                    @clearFilters="searchTerm = ''"
                    :search="searchTerm"
                  ></devices-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-dialog
        transition="custom-dialog-transition"
        v-model="modalOptions.open"
        width="560"
      >
        <my-account-modal
          :modalOptions="modalOptions"
          @modal-close="modalClose"
          :key="modalRender"
          ref="myAccountModal"
        />
      </v-dialog>

      <v-dialog
        transition="custom-dialog-transition"
        width="560px"
        v-model="isOpenTwoFactorAuthModal"
      >
        <two-factor-auth-modal
          :secret-key="secretKey"
          @enable-2fa="change2fa"
          persistent
          :code-qr="codeQr"
          @modal-close="closeTwoFactorAuthModal"
        />
      </v-dialog>

      <v-dialog
        transition="custom-dialog-transition"
        width="480"
        v-model="isOpenTwoFactorDisableConfirmationModal"
      >
        <two-factor-auth-disable-confirmation
          @modal-close="closeTwoFactorAuthConfirmDisableModal"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ActionModalMixin from "@/mixins/ActionModalMixin";
import ChangePasswordMixin from "@/mixins/ChangePasswordMixin";
import ChangeEmailAddressMixin from "@/mixins/ChangeEmailAddressMixin";
import MyAccountModal from "@/components/modal/MyAccountModal";
import TwoFactorAuthModal from "@/components/modal/TwoFactorAuthModal";
import TwoFactorAuthDisableConfirmation from "@/components/modal/TwoFactorAuthDisableConfirmation";
import DevicesTable from "@/components/dataIterator/tables/DevicesTable.vue";
import DataIteratorSearch from "../components/dataIterator/DataIteratorSearch.vue";
import ButtonSwitch from "../components/buttons/ButtonSwitch.vue";
import SelectDropdown from "../components/SelectDropdown.vue";

import Api from "@/apis/Api";

export default {
  components: {
    MyAccountModal,
    TwoFactorAuthModal,
    TwoFactorAuthDisableConfirmation,
    DevicesTable,
    DataIteratorSearch,
    ButtonSwitch,
    SelectDropdown,
  },
  mixins: [ActionModalMixin, ChangePasswordMixin, ChangeEmailAddressMixin],
  mounted() {
    this.reloadMyAccount();
  },
  data() {
    return {
      activeTab: 0,
      searchTerm: "",
      faChanged: false,
      twoFactorAuthEnabled: "",
      formValue: [],
      emailAddress: "",
      personalInformation: [
        {
          title: this.$t('form.label.firstName'),
          name: "first_name",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {
              field: this.$t('form.label.firstName')
            }),
            (v) =>
              (v && v.length <= 20) ||
              this.$t('form.validation.maxLength', {
                field: this.$t('form.label.firstName'),
                length: 20
              }),
          ],
        },
        {
          title: this.$t('form.label.lastName'),
          name: "last_name",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {
              field: this.$t('form.label.lastName')
            }),
            (v) =>
              (v && v.length <= 20) ||
              this.$t('form.validation.maxLength', {
                field: this.$t('form.label.lastName'),
                length: 20
              }),
          ],
        },
        {
          title: this.$t('form.label.companyName'),
          name: "company_name",
          type: "text",
        },
        {
          title: this.$t('form.label.language'),
          name: "language",
          type: "select",
          options: this.getLanguages(),
          rules: [(v) => !!v || this.$t('form.validation.required', {
            field: this.$t('form.label.language')
          })],
        },
      ],
      isOpenTwoFactorAuthModal: false,
      isOpenTwoFactorDisableConfirmationModal: false,
      codeQr: null,
      secretKey: "",
      loading: false,
      newDeviceDetectionOptions: [
        {
          value: "ip, device, browser",
          text: this.$t('form.option.deviceDetection.ipDeviceBrowser')
        },
        {
          value: "ip, device",
          text: this.$t('form.option.deviceDetection.ipDevice')
        },
        {
          value: "ip, browser",
          text: this.$t('form.option.deviceDetection.ipBrowser')
        },
        {
          value: "device, browser",
          text: this.$t('form.option.deviceDetection.deviceBrowser')
        },
        {value: "ip", text: this.$t('form.option.deviceDetection.ip')},
        {value: "device", text: this.$t('form.option.deviceDetection.device')},
        {value: "browser", text: this.$t('form.option.deviceDetection.browser')},
      ],
    };
  },
  methods: {
    change2fa: function (value) {
      this.faChanged = value;
    },
    reloadMyAccount() {
      Api.get("/home").then((response) => {
        this.formValue = {
          first_name: response.data.user.first_name,
          last_name: response.data.user.last_name,
          company_name: response.data.user.company_name,
          language: response.data.user.language,
          new_device_detection: response.data.user.new_device_detection,
        };
        this.twoFactorAuthEnabled = response.data.user.two_factor_auth_enabled;
        this.emailAddress = response.data.user.email;
      });
    },
    saveChanges() {
      if (!this.$refs.form.validate()) {
        return;
      }
      Api.put(`/user/edit`, this.formValue)
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.account.updateUser.success'),
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: error.response.data.message,
          });
        });
    },
    toggleTwoFactorAuth() {
      if (this.twoFactorAuthEnabled !== true) {
        this.enableTwoFactorAuth();
      } else {
        this.isOpenTwoFactorDisableConfirmationModal = true;
      }
    },
    closeTwoFactorAuthModal() {
      if (!this.faChanged) {
        this.twoFactorAuthEnabled = false;
      }
      this.isOpenTwoFactorAuthModal = false;

      this.reloadMyAccount();
    },
    closeTwoFactorAuthConfirmDisableModal() {
      this.isOpenTwoFactorDisableConfirmationModal = false;
      this.reloadMyAccount();
    },
    enableTwoFactorAuth() {
      this.loading = true;
      Api.post("/user/two-factor-authentication")
        .then(() => {
          Api.get("/user/two-factor-qr-code")
            .then((response) => {
              this.codeQr = response.data.svg;
              Api.get("/user/two-factor-secret-key")
                .then((response) => {
                  this.secretKey = response.data.secretKey;
                  this.loading = false;
                  this.isOpenTwoFactorAuthModal = true;
                })
                .catch((error) => {
                  this.$store.dispatch("addAlert", {
                    success: false,
                    errorMessage: error.response.data.message,
                  });
                });
            })
            .catch((error) => {
              this.$store.dispatch("addAlert", {
                success: false,
                errorMessage: error.response.data.message,
              });
            });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: error.response.data.message,
          });
        });
    },
    changeDeviceDetection(detectionMethod) {
      this.formValue["new_device_detection"] = detectionMethod.value;
    },
    handleLanguage(language) {
      this.formValue.language = language.value;
    },
    getLanguages() {
      return this.$store.state.home.app.languages.map(lang => {
        return {
          text: this.$t(`languages.${lang}`),
          value: lang
        }
      })
    }
  },
  watch: {
    isAnyOpen: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  computed: {
    isAnyOpen: function () {
      return (
        this.modalOptions.open ||
        this.isOpenTwoFactorDisableConfirmationModal ||
        this.isOpenTwoFactorAuthModal
      );
    },
  },
};
</script>

<style lang="scss" scoped>
label + .v-input {
  margin-top: 10px;
}

label + .v-btn {
  margin-top: 32px;
}

.v-card__actions {
  border-top: 1px solid var(--v-gray-lighten5);
}

.v-tab {
  min-width: unset;
  padding-left: 4px;
  padding-right: 4px;
}

.theme--light.v-tabs::v-deep .v-slide-group__prev,
.theme--light.v-tabs::v-deep .v-slide-group__next {
  display: none !important;
}
</style>
